import { styled } from '@mui/material';
import { clsx } from 'clsx';

import Button from '../Button';
import { Card, CardActions, CardContent, CardMedia } from '../Card';
import ChatBubble from '../ChatBubble';
import Typography from '../Typography';

import ActionCardHeader from './ActionCard.Header';

import type { ButtonProps } from '../Button';
import type { CardProps } from '../Card';
import type { ChatBubbleProps } from '../ChatBubble';
import type { FC, ReactElement } from 'react';

interface ActionCardProps {
  actions?: Array<{
    buttonProps?: Omit<ButtonProps, 'onClick'>;
    label: string;
    onClick?: ButtonProps['onClick'];
  }>;

  background?:
    | 0
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | string;
  cardMedia?: ReactElement;
  children?: ReactElement | ChatBubbleProps | string;
  className?: string;
  isDarkTheme?: boolean;
  label: string;
  onClose?: () => void;
  onDelete?: () => void;
  onEdit?: () => void;
  onPublish?: () => void;
  onUnpublish?: () => void;
  status?: 'expired' | 'published' | 'draft';
  sx?: CardProps['sx'];
  title: string;
}

interface StyledCardProps extends CardProps {
  backgroundImage: string;
  isDarkTheme?: boolean;
}

/* eslint-disable sort-keys,@typescript-eslint/no-magic-numbers -- CSS Selectors shouldn't be alphabetically sorted. CSS values make little sense as constants. */
const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => {
    const props = [
      'backgroundImage',
      'isDarkTheme',
      'isSessionToday',
      'peerInsight',
      'dismissedTill',
      'isDismissable',
      'ctaLabel',
      'ctaLink',
      'imageUrl',
      'isImageInline',
    ];
    return !props.includes(prop as string);
  },
})<StyledCardProps>(({ backgroundImage, isDarkTheme, theme }) => ({
  backgroundColor: isDarkTheme
    ? theme.palette.common.black
    : theme.palette.common.white,
  backgroundImage: `url(${backgroundImage});`,
  backgroundPosition: 'top center',
  backgroundSize: 'cover',
  color: isDarkTheme ? theme.palette.common.white : theme.palette.text.primary,
  display: 'flex',
  flexDirection: 'column',
  flexShrink: 0,
  height: 414,
  width: 256,
}));

const StyledCardMedia = styled(CardMedia)(() => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',

  '> img': {
    maxHeight: 127,
  },
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  paddingTop: 0,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

const StyledCardActions = styled(CardActions)(({ theme }) => ({
  marginTop: 'auto',
  padding: theme.spacing(2),
}));
/* eslint-enable sort-keys,@typescript-eslint/no-magic-numbers */

const backgrounds = import.meta.glob<Record<string, string>>('./assets/*.png', {
  eager: true,
});

const ActionCard: FC<ActionCardProps> = ({
  actions = [],
  background,
  cardMedia,
  children,
  className,
  label,
  isDarkTheme = false,
  onClose,
  onDelete,
  onEdit,
  onPublish,
  onUnpublish,
  status,
  title,
  ...rest
}) => {
  const classes = clsx('mnt-action-card', className);

  const hasCustomBackground = typeof background === 'string';
  const image = hasCustomBackground
    ? background
    : backgrounds[`./assets/bg-${background}.png`]?.default;

  let content = children as ReactElement;

  if (typeof children === 'string') {
    content = <Typography variant="body2">{children}</Typography>;
  } else if (children && (children as ChatBubbleProps).message) {
    content = <ChatBubble {...(children as ChatBubbleProps)} />;
  }

  return (
    <StyledCard
      backgroundImage={image || ''}
      className={classes}
      isDarkTheme={isDarkTheme}
      {...rest}
    >
      <ActionCardHeader
        isDarkTheme={isDarkTheme}
        label={label}
        status={status}
        onClose={onClose}
        onDelete={onDelete}
        onEdit={onEdit}
        onPublish={onPublish}
        onUnpublish={onUnpublish}
      />

      {cardMedia && <StyledCardMedia>{cardMedia}</StyledCardMedia>}

      <StyledCardContent>
        {!!title && (
          <Typography sx={{ marginBottom: 1 }} variant="h4">
            {title}
          </Typography>
        )}
        {content}
      </StyledCardContent>

      {!!actions.length && (
        <StyledCardActions>
          {actions.map(({ label: actionLabel, onClick, buttonProps }) => (
            <Button
              key={actionLabel}
              variant="outlined"
              fullWidth
              onClick={onClick}
              {...buttonProps}
            >
              {actionLabel}
            </Button>
          ))}
        </StyledCardActions>
      )}
    </StyledCard>
  );
};
ActionCard.displayName = 'ActionCard';

export default ActionCard;
export type { ActionCardProps };
