// @ts-nocheck -- TODO: Update Boulder page to use new endpoints and APIs.
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ZERO } from '@/constants';
import { useCurrentGrowthBaseUrl } from '@/hooks';
import { BoulderColor } from '@/types';
import { Card, Typography } from '@/ui';
import { getBoulderColor } from '@/utils';

import type { Milestone } from '@/types';
import type { FC } from 'react';

import BoulderImage from '@/components/Growth/BoulderImage';

interface BoulderCardProps {
  disableActions?: boolean;
  milestone: Milestone;
}

const BoulderCard: FC<BoulderCardProps> = ({
  milestone,
  disableActions = false,
}) => {
  const [color, setColor] = useState<string>('');
  const [stepsLabel, setStepsLabel] = useState<string>('');
  const growthBase = useCurrentGrowthBaseUrl();
  const navigate = useNavigate();

  useEffect(() => {
    let c = '';
    let label = '';
    if (milestone.totalSteps === ZERO) {
      label = 'No steps added';
    } else if (milestone.completeSteps === milestone.totalSteps) {
      label = 'All steps complete!';
    } else {
      label = `${milestone.completeSteps} of ${milestone.totalSteps} complete`;
    }

    switch (getBoulderColor(milestone.id)) {
      case BoulderColor.SUNNY:
        c = 'text-sunny-600';
        break;
      case BoulderColor.OCEAN:
        c = 'text-ocean-600';
        break;
      case BoulderColor.SKY:
        c = 'text-sky-600';
        break;
      case BoulderColor.POPPY:
        c = 'text-poppy-600';
        break;
      case BoulderColor.BLOOM:
        c = 'text-bloom-600';
        break;
      case BoulderColor.SAGE:
        c = 'text-sage-600';
        break;
      default:
        c = '';
        break;
    }

    setStepsLabel(label);
    setColor(c);
  }, [milestone]);

  const onClick = () => {
    if (!disableActions) {
      navigate(`${growthBase}/milestone/${milestone.id}`);
    }
  };

  return (
    <Card
      className="-mb-1 flex h-20 w-full cursor-pointer gap-4 rounded-none border border-solid border-sage-300 bg-white p-3 first:rounded-t-lg first:border-b-0 last:rounded-b-xl hover:bg-sage-100"
      variant="outlined"
      onClick={onClick}
    >
      <div className="size-14">
        <BoulderImage milestone={milestone} />
      </div>
      <div className="flex w-full flex-col ">
        <div>
          <Typography
            className="line-clamp-2 overflow-hidden text-ellipsis"
            variant="subtitle2"
          >
            {milestone.title}
          </Typography>
        </div>
        <Typography className={color} variant="body2">
          {stepsLabel}
        </Typography>
      </div>
    </Card>
  );
};

export default BoulderCard;
