import {
  PutObjectCommand,
  S3Client,
} from '@aws-sdk/client-s3';

import type { UploadType } from '@/types';
import type {
  ObjectCannedACL} from '@aws-sdk/client-s3';

const s3 = new S3Client({
  credentials: {
    accessKeyId: import.meta.env.VITE_SPACES_ACCESS_KEY,
    secretAccessKey: import.meta.env.VITE_SPACES_SECRET_ACCESS_KEY,
  },
  endpoint: import.meta.env.VITE_SPACES_ENDPOINT,
  region: import.meta.env.VITE_SPACES_REGION,
});

export const uploadToBucket = async (
  filename: string,
  Body: File,
  path: UploadType,
  ACL: ObjectCannedACL = 'public-read',
): Promise<string> => {
  const ext = Body.name.split('.').pop();
  const Key = `${path}/${filename}-${Date.now()}.${ext}`;

  const params = {
    ACL,
    Body,
    Bucket: import.meta.env.VITE_SPACES_BUCKET,
    Key,
  };

  await s3.send(new PutObjectCommand(params));

  return Key;
};
