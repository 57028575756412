import { Button, Typography } from '@/ui';

import type { FC } from 'react';

interface DesktopProps {
  open: () => void;
}

const Desktop: FC<DesktopProps> = ({ open }) => (
  <div className="mt-6 hidden gap-4 rounded-md border border-solid border-cement-200 p-6 lg:flex">
    <img
      alt="Have a question?"
      className="size-16"
      src="https://mento-space.nyc3.digitaloceanspaces.com/assets/jamie-membership-select.png"
    />
    <div className="flex flex-col gap-2">
      <Typography variant="subtitle1">Need help deciding?</Typography>
      <Typography className="text-cement-400" variant="body2">
        Schedule a call to learn more about Mento and the right plan for you.
      </Typography>
      <Button variant="outlined" fullWidth onClick={open}>
        Book a call
      </Button>
    </div>
  </div>
);

export default Desktop;
