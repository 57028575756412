import { Avatar, Button, Icon, Typography } from '@/ui';

import type { User } from '@/types';
import type { FC } from 'react';

interface BannerProps {
  onEdit?: () => void;
  user: User;
}

const Banner: FC<BannerProps> = ({ user, onEdit = undefined }) => (
  <div className="block flex-1 bg-cover bg-center bg-no-repeat lg:container lg:flex lg:flex-row">
    <div className="ml-0 flex items-start justify-between gap-4">
      <div className="ml-4 size-32 lg:ml-0 lg:size-64">
        <div className="block size-32 lg:relative lg:top-20 lg:size-64">
          <Avatar
            className="size-full text-4xl"
            firstName={user.firstName}
            lastName={user.lastName}
            src={user.avatarUrl ?? ''}
          />
        </div>
      </div>

      {!!onEdit && (
        <div className="mr-4 flex shrink-0 flex-wrap gap-4 pt-4 md:hidden lg:mr-0 lg:mt-0">
          <Button
            className="w-10 min-w-fit rounded-2xl p-1.5"
            color="secondary"
            variant="contained"
            onClick={onEdit}
          >
            <Icon>edit</Icon>
          </Button>
        </div>
      )}
    </div>
    <div className="mx-4 flex flex-1 flex-col justify-end pb-4 md:mx-6 lg:ml-14">
      <div className="flex items-center gap-6">
        <div className="flex items-center justify-center gap-2 text-white">
          <Typography className="text-white" color="white" variant="h4">
            {user.fullName}
          </Typography>
          {user.profile?.pronouns && (
            <Typography className="-mb-1 opacity-70" color="white" variant="h5">
              {`(${user.profile.pronouns})`}
            </Typography>
          )}
        </div>
        {!!onEdit && (
          <div className="hidden shrink-0 gap-4 md:flex">
            <Button
              className="w-10 min-w-fit rounded-2xl p-1.5"
              color="secondary"
              variant="contained"
              onClick={onEdit}
            >
              <Icon>edit</Icon>
            </Button>
          </div>
        )}
      </div>
      <Typography className="text-white" color="white" variant="h6">
        {user.profile?.title ?? ''}
        {user.profile?.company ? `, ${user.profile.company}` : ''}
      </Typography>
      {user.coachProfile?.focus && (
        <Typography className="pt-4 text-white" color="white" variant="h5">
          {user.coachProfile.focus}
        </Typography>
      )}
    </div>
  </div>
);

export default Banner;
