import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ONE } from '@/constants';
import { useCurrentUser, useGrowthPlan, useView } from '@/hooks';
import { Icon, IconButton, Typography } from '@/ui';
import { track } from '@/utils';

import Boulders from './Boulders';
import Footer from './Footer';

import type { FC } from 'react';

const Garden: FC = () => {
  const { plan } = useGrowthPlan();
  const navigate = useNavigate();

  const { user } = useCurrentUser();
  const { selectedUser, isOverview } = useView();

  const completedMilestones = plan.milestones.filter(
    (milestone) => milestone.completedAt,
  );
  const viewUser = isOverview ? selectedUser : user;
  const backgroundImage = {
    backgroundImage:
      'url("https://mento-space.nyc3.digitaloceanspaces.com/assets/boulders-complete-bg.jpg")',
  };

  const title = completedMilestones.length
    ? `${completedMilestones.length} completed milestones`
    : 'Your completed milestones will live here.';
  const body = !completedMilestones.length
    ? 'We are excited to see your progress.'
    : '';

  const back = () => navigate(-ONE);

  useEffect(() => {
    track('View Boulder Garden');
  }, []);

  if (!user.id || (isOverview && !selectedUser.id)) return null;

  return (
    <div
      className="flex size-full flex-col bg-cover bg-center bg-no-repeat text-white"
      style={backgroundImage}
    >
      <div className="flex size-full flex-col">
        <div className="headline flex pt-6 text-white lg:pl-8">
          <IconButton className="mr-2" color="white" onClick={back}>
            <Icon>chevron_left</Icon>
          </IconButton>
          <Typography variant="h5">{title}</Typography>
        </div>
        <Typography
          className="text-center lg:ml-20 lg:text-left"
          variant="body1"
        >
          {body}
        </Typography>
        <Boulders milestones={completedMilestones} />
      </div>
      <Footer createdAt={new Date(viewUser.createdAt)} />
    </div>
  );
};

export default Garden;
