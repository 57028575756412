import {
  init,
  reactRouterV6BrowserTracingIntegration,
  replayIntegration,
  wrapUseRoutes,
} from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
  useRoutes,
} from 'react-router-dom';

import { AppRoutes } from './Routes';

init({
  // disable sentry tracking errors on local development
  beforeSend: (event) => {
    if (import.meta.env.VITE_APP_ENV === 'local') {
      return null;
    }
    return event;
  },
  dsn: 'https://0827e5ce059a1cdd5ce50a4d911ff87c@o4505796051992576.ingest.sentry.io/4505830024544256',
  environment: import.meta.env.VITE_APP_ENV,
  integrations: [
    replayIntegration(),
    reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  tracesSampleRate: 1.0,
});

const useSentryRoutes = wrapUseRoutes(useRoutes);

const AppRouter = () => useSentryRoutes(AppRoutes);

export default AppRouter;
