import { useQueryClient } from '@tanstack/react-query';
import { useController, useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import {
  QUERY_KEY_GROWTH_PLANS,
  QUERY_KEY_MILESTONE_STEPS,
  QUERY_KEY_MILESTONES,
} from '@/constants';
import {
  useCreateMilestoneStep,
  useCurrentUser,
  useGrowthPlan,
  useGrowthPlanSteps,
  useUpdateMilestoneStep,
} from '@/hooks';
import {
  Button,
  DatePicker,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Icon,
  MenuItem,
  TextField,
  Typography,
} from '@/ui';

import type { MilestoneStep } from '@/types';
import type { FC } from 'react';

interface StepDialogProps {
  isOpen: boolean;
  milestoneId?: string;
  setOpen: (value: boolean) => void;
  step?: Partial<MilestoneStep>;
}

const StepDialog: FC<StepDialogProps> = ({
  isOpen,
  setOpen,
  step = {},
  milestoneId: milestoneIdValue,
}) => {
  const {
    control,
    formState: { isValid },
    handleSubmit,
    reset,
  } = useForm<{ dueDate: null | Date; milestoneId: string; title: string }>({
    defaultValues: {
      dueDate: step.dueDate ? new Date(step.dueDate) : null,
      milestoneId: step.milestoneId ?? milestoneIdValue ?? '',
      title: step.title ?? '',
    },
  });
  const { field: title } = useController({
    control,
    name: 'title',
    rules: { required: true },
  });
  const { field: milestoneId } = useController({
    control,
    name: 'milestoneId',
  });
  const { field: dueDate } = useController({ control, name: 'dueDate' });
  const queryClient = useQueryClient();
  const createMilestoneStep = useCreateMilestoneStep();
  const updateMilestoneStep = useUpdateMilestoneStep();

  const { teamId = '', id: userId } = useParams();
  const { user } = useCurrentUser();

  const { plan, refetch } = useGrowthPlan();
  const { refetch: refetchSteps } = useGrowthPlanSteps();
  const isLoading =
    createMilestoneStep.isLoading || updateMilestoneStep.isLoading;
  const selectedUser = userId ?? user.id;
  const inProgressMilestones = plan.milestones.filter(
    ({ completedAt }) => !completedAt,
  );

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const onSubmit = handleSubmit((data) => {
    const onSuccess = () => {
      reset();
      refetch();
      refetchSteps();
      queryClient.invalidateQueries([
        QUERY_KEY_GROWTH_PLANS,
        { teamId, userId: selectedUser },
      ]);
      queryClient.invalidateQueries([QUERY_KEY_MILESTONES, milestoneId.value]);
      queryClient.invalidateQueries([QUERY_KEY_MILESTONE_STEPS]);
      setOpen(false);
    };
    if (step.id) {
      updateMilestoneStep.mutate(
        {
          id: step.id,
          ...data,
          dueDate: data.dueDate ? data.dueDate.toISOString() : null,
          growthPlanId: plan.id,
        },
        { onSuccess },
      );
    } else {
      createMilestoneStep.mutate(
        {
          ...data,
          dueDate: data.dueDate ? data.dueDate.toISOString() : null,
          growthPlanId: plan.id,
        },
        {
          onSuccess,
        },
      );
    }
  });

  return (
    <Dialog maxWidth="md" open={isOpen} fullWidth onClose={handleClose}>
      <DialogContent>
        <form id="add-step-form" onSubmit={onSubmit}>
          <Grid spacing={4} container>
            <Grid md={5} xs={12} item>
              <div className="flex size-12 items-center justify-center rounded-full bg-sage-600 text-white">
                <Icon>hiking</Icon>
              </div>
              <Typography className="my-4" variant="h4">
                {step.title ? 'Edit' : 'Create a'}&nbsp;step
              </Typography>
              <Typography variant="body1">
                Steps help you make progress on your milestones and one year
                vision. Each milestone can support up to eight steps to keep it
                focused.
              </Typography>
            </Grid>
            <Grid className="mt-4 flex flex-col gap-4" md={7} xs={12} item>
              <TextField
                className="w-full"
                label="Step"
                rows={4}
                multiline
                {...title}
              />
              {!!inProgressMilestones.length && (
                <TextField
                  className="w-full text-cement-400"
                  label="Tag milestone"
                  select
                  {...milestoneId}
                >
                  <MenuItem>&nbsp;</MenuItem>
                  {inProgressMilestones.map(({ title: label, id: value }) => (
                    <MenuItem key={`step-data-${label}-${value}`} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              <DatePicker
                className="w-full"
                label="Add due date"
                disablePast
                {...dueDate}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <div className="ml-4 flex items-end justify-end gap-4">
          <Button disabled={isLoading} variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={!isValid}
            form="add-step-form"
            loading={isLoading}
            type="submit"
            variant="contained"
          >
            Save
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default StepDialog;
