import { clsx } from 'clsx';

import { Button, Paper } from '@/ui';

import type { MessageThreadMessage } from '@/types';
import type { FC, HTMLAttributes } from 'react';

export type SummaryBaseProps = {
  message: MessageThreadMessage;
  negativeLean?: boolean;
  onConfirm: (confirmed: boolean, messageId: string, label: string) => void;
} & HTMLAttributes<HTMLDivElement>;

const SummaryBase: FC<SummaryBaseProps> = ({
  children,
  className,
  message,
  negativeLean,
  onConfirm,
}) => {
  const classes = clsx(
    'text-summary min-h-25 relative mt-7 w-full max-w-156',
    className,
  );
  const gradientClasses = clsx(
    'absolute inset-x-1 inset-y-10 z-0 -rotate-3 bg-gradient-to-r from-poppy-500 to-bloom-500 opacity-50 blur-3xl',
  );
  const paperClasses = clsx(
    'min-h-25 relative z-10 size-full p-8',
    { 'rotate-half': !negativeLean },
    { '-rotate-half': negativeLean },
  );
  const buttonsWrapper = clsx(
    'buttons mb-10 flex flex-wrap items-center justify-center gap-4 pt-8',
  );

  const skipCTA = "Let's talk about this some more";
  const acceptCTA = "Looks good! Let's continue";

  const handleClick = (confirmed: boolean, buttonLabel: string) => () =>
    onConfirm(confirmed, message.id, buttonLabel);
  const hasConfirmed = message.confirmed !== null;

  return (
    <>
      <div className={classes}>
        <div className={gradientClasses} />
        <Paper className={paperClasses} elevation={6}>
          {children}
        </Paper>
      </div>
      {!hasConfirmed && (
        <div className={buttonsWrapper}>
          <Button
            color="secondary"
            variant="contained"
            light
            onClick={handleClick(false, skipCTA)}
          >
            {skipCTA}
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={handleClick(true, acceptCTA)}
          >
            {acceptCTA}
          </Button>
        </div>
      )}
    </>
  );
};

export default SummaryBase;
