import { CircularProgress } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ONE, QUERY_KEY_MESSAGE_THREADS } from '@/constants';
import {
  getOrderedMessages,
  useCreateMessageThreadMessage,
  useCurrentUser,
  useGeMessageThreadMessages,
} from '@/hooks';
import { MessageRole } from '@/types';
import { Button, TextFieldWithButton, Typography } from '@/ui';
import { getMessageType, sleep, track } from '@/utils';

import Header from '../Header';

import Message from './Message';

import type { MessageThreadMessage } from '@/types';

import './index.css';

const CoachAISession = () => {
  const { user: currentUser } = useCurrentUser();
  const navigate = useNavigate();
  const [latestSentMessage, setLatestSentMessage] = useState('');
  const [isWorking, setIsWorking] = useState(false);
  const { messageThreadId = '' } = useParams();
  const chatParent = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const topOfChat = useRef<HTMLDivElement>(null);
  const queryClient = useQueryClient();
  const {
    data,
    hasNextPage,
    fetchNextPage,
    isLoading: isLoadingMessages,
    refetch,
    isRefetching,
  } = useGeMessageThreadMessages(
    {
      messageThreadId,
    },
    {
      onSettled: () => {
        setLatestSentMessage('');
      },
    },
  );
  const creatMessageThreadMessage = useCreateMessageThreadMessage();

  const hasScrolledOnInitialPage = useRef(false);

  // https://css-tricks.com/books/greatest-css-tricks/pin-scrolling-to-bottom/
  // we need to trigger a manual scroll in order to make auto scroll to bottom work with css
  // We only do this on the first page loaded.
  useEffect(() => {
    const domNode = chatParent.current;
    if (domNode && data?.pages && data.pages.length <= ONE) {
      domNode.scrollTop = domNode.scrollHeight;
      hasScrolledOnInitialPage.current = true;
    }
  }, [data]);

  const loadPreviousMessages = async () => {
    const domNode = chatParent.current;

    if (!domNode) {
      return;
    }
    const oldHeight = domNode.scrollHeight;
    await fetchNextPage();
    await sleep(ONE); // Await for render to happen

    domNode.scrollTop = domNode.scrollHeight - oldHeight;
  };

  const allItems: MessageThreadMessage[] = getOrderedMessages(
    data?.pages || [],
  );

  useEffect(() => {
    if (!inputRef.current) {
      return;
    }
    if (!isWorking) {
      inputRef.current.focus();
    }
  }, [isWorking]);

  useEffect(() => {
    if (!topOfChat.current) {
      return undefined;
    }
    const observer = new IntersectionObserver(([entry]) => {
      if (!entry) {
        return;
      }
      if (entry.isIntersecting && hasNextPage) {
        loadPreviousMessages();
      }
    });

    observer.observe(topOfChat.current);

    return () => observer.disconnect();
  }, [topOfChat, hasNextPage]);

  useEffect(() => {
    const lastMessage = allItems.at(-ONE);
    if (creatMessageThreadMessage.isLoading || isRefetching) {
      return setIsWorking(true);
    }
    // If last message has a function ID AND it has not been
    // confirmed, we consider this to be the first display.
    // We want to track these.
    if (lastMessage && lastMessage.functionId && !lastMessage.confirmed) {
      track('Structured Data/stage displayed', {
        stage: lastMessage.title,
        type: getMessageType(lastMessage),
      });
    }
    return setIsWorking(false);
  }, [creatMessageThreadMessage.isLoading, isRefetching, data?.pages[0]]);

  const goBack = () => {
    queryClient.invalidateQueries({ queryKey: [QUERY_KEY_MESSAGE_THREADS] });
    navigate('/m/workflow');
  };

  const onSubmit = (content: string) => {
    // add the message to the UI first cause refetch is slow
    setLatestSentMessage(content);

    creatMessageThreadMessage.mutate(
      {
        content,
        messageThreadId,
      },
      {
        onSuccess: () => refetch(),
      },
    );
  };

  const beginingOfChat = !data?.pages[data.pages.length - ONE]?.hasNextPage;

  return (
    <div className="size-full">
      <Header
        backButton={
          <Button color="primary" variant="outlined" onClick={goBack}>
            End session
          </Button>
        }
      />
      <div className="flex size-full justify-center">
        <div className="flex w-full flex-col">
          {isLoadingMessages && (
            <div className="mx-auto max-w-full py-11 md:max-w-156">
              <CircularProgress />
            </div>
          )}
          {!isLoadingMessages && (
            <div
              ref={chatParent}
              className="scroller mb-5 overflow-y-auto overflow-x-hidden px-5 md:px-20"
            >
              {beginingOfChat && <div className="h-28 w-full" />}
              {!beginingOfChat && (
                <div
                  ref={topOfChat}
                  className="flex items-center justify-center py-5"
                >
                  <CircularProgress />
                </div>
              )}
              {allItems.map((message) => (
                <Message
                  key={message.id}
                  currentUser={currentUser}
                  message={message}
                />
              ))}
              {!!latestSentMessage.length && (
                <div className="mx-auto max-w-full md:max-w-156">
                  <Message
                    message={{
                      content: latestSentMessage,
                      extendable: {},
                      id: '000000',
                      messageThreadId,
                      role: MessageRole.USER,
                      sortKey: new Date().getTime() / 1000, // seconds since epoch, as a placeholder
                    }}
                  />
                </div>
              )}
              {isWorking && (
                <div className="mx-auto max-w-full md:max-w-144">
                  <CircularProgress
                    className="float-right"
                    color="success"
                    size={16}
                  />
                </div>
              )}
              <div className="anchor" />
            </div>
          )}

          <div className="mx-auto mt-auto max-w-full md:max-w-156">
            <div className="mb-8 mt-auto justify-self-end align-bottom">
              <TextFieldWithButton
                ref={inputRef}
                buttonIcon="arrow_upward"
                disabled={
                  isWorking ||
                  !!data?.pages[0]?.items[0]?.functionId ||
                  !!data?.pages[0]?.items[0]?.messageThreadEnded
                }
                placeholder="Talk with Mento"
                onSubmit={onSubmit}
              />
              <Typography
                className="inline-block w-full pt-5 text-center text-cement-400"
                variant="caption"
              >
                Mento may make mistakes, please don't rely on its information.
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachAISession;
