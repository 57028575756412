import { Skeleton } from '@mui/material';
import MuiAvatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import { clsx } from 'clsx';

import { ONE, ZERO } from '@/constants';

import Icon from '../Icon';

import type { AvatarProps as MuiAvatarProps } from '@mui/material/Avatar';
import type { FC } from 'react';

type AvatarSizes = 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
interface AvatarProps extends MuiAvatarProps {
  firstName?: string;
  icon?: string;
  iconClassName?: string;
  lastName?: string;
  loading?: boolean;
  size?: AvatarSizes;
}

/* eslint-disable sort-keys -- Sorted by size, not alphabetically. */
const avatarSizeMap = {
  xs: { fontSize: 10, size: 18 },
  s: { fontSize: 14, size: 24 },
  m: { fontSize: 20, size: 48 },
  l: { fontSize: 36, size: 80 },
  xl: { fontSize: 76, size: 144 },
  xxl: { fontSize: 140, size: 256 },
};
/* eslint-enable sort-keys */

const StyledAvatar = styled(MuiAvatar)<AvatarProps>(({ size, theme }) => ({
  background: theme.extendedColors.bloom[500],
  fontSize: theme.typography.pxToRem(
    avatarSizeMap[size as AvatarSizes].fontSize,
  ),
  height: theme.typography.pxToRem(avatarSizeMap[size as AvatarSizes].size),
  width: theme.typography.pxToRem(avatarSizeMap[size as AvatarSizes].size),
}));

const Avatar: FC<AvatarProps> = ({
  firstName = '',
  lastName = '',
  icon = '',
  iconClassName = '',
  loading = false,
  size = 'm',
  className,
  src,
  ...rest
}) => {
  if (loading) {
    return (
      <Skeleton
        height={avatarSizeMap[size].size}
        variant="circular"
        width={avatarSizeMap[size].size}
      />
    );
  }

  const classes = clsx('mnt-avatar', className);
  const fnameInitial = firstName[0]?.substring(ZERO, ONE).toUpperCase() ?? '';
  const lnameInitial = lastName[0]?.substring(ZERO, ONE).toUpperCase() ?? '';

  // If there's no names to get initials from,
  // no 'icon' prop defined,
  // and no src prop set,
  // use 'person' icon as default.
  // Else, displayIcon should be
  // whatever the 'icon' prop is.
  const displayIcon = !firstName && !lastName && !icon ? 'person' : icon;

  return (
    <StyledAvatar
      {...rest}
      alt={`${firstName} ${lastName}`}
      className={classes}
      size={size}
      src={src}
    >
      {displayIcon ? (
        <Icon
          className={iconClassName}
          sx={{ fontSize: avatarSizeMap[size].fontSize }}
        >
          {displayIcon}
        </Icon>
      ) : (
        `${fnameInitial}${lnameInitial}`
      )}
    </StyledAvatar>
  );
};
Avatar.displayName = 'Avatar';

export default Avatar;
export { avatarSizeMap };
export type { AvatarProps, AvatarSizes };
