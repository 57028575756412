import { useNavigate } from 'react-router-dom';

import { Button, Card, CardContent, Icon, Typography } from '@/ui';

import type { GrowthPlan } from '@/types';
import type { FC } from 'react';

interface EmptyMilestoneCardProps {
  growthPlan: GrowthPlan;
}

const EmptyMilestoneCard: FC<EmptyMilestoneCardProps> = ({ growthPlan }) => {
  const navigate = useNavigate();
  const handleStartClick = () => {
    navigate(`../growth/${growthPlan.id}`);
  };

  return (
    <Card>
      <CardContent className="w-96">
        <div className="mb-4 grid size-12 place-items-center rounded-full bg-sage-200">
          <Icon className="!text-2xl text-sage-600">landscape</Icon>
        </div>
        <Typography className="mb-2" variant="h5">
          No milestones in the works
        </Typography>
        <Typography className="mb-4" variant="body2">
          You can co-create the growth plan by adding steps, milestones, and
          work through things together.
        </Typography>
        <Button
          className="w-full"
          variant="outlined"
          onClick={handleStartClick}
        >
          Start
        </Button>
      </CardContent>
    </Card>
  );
};

export default EmptyMilestoneCard;
