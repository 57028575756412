import { captureException } from '@sentry/react';
import { useCallback, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { uploadToBucket } from '@/api';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@/ui';

import DragAndDrop from './dragAndDrop';
import Preview from './preview';

import type { UploadType } from '@/types';
import type { FC } from 'react';

interface UploadImageProps {
  filename?: string;
  onClose: (url?: string) => void;
  type: UploadType;
}

const UploadImage: FC<UploadImageProps> = ({
  filename = '',
  type,
  onClose,
}) => {
  const MAX_NUMBER_OF_FILES = 1;
  const MAX_UPLOAD_FILE_SIZE = 2097152; // up to 2 MB

  const inputRef = useRef<HTMLInputElement | null>(null);

  const [processing, setProcessing] = useState(false);
  const [preparedFile, setPreparedFile] = useState<File | null>(null);
  const [uploadedImage, setUploadedImage] = useState<string | null | undefined>(
    null,
  );

  const onDrop = useCallback((acceptedFile: File[]) => {
    const file = acceptedFile[0];
    if (file) {
      setUploadedImage(URL.createObjectURL(file));
      setPreparedFile(file);
      setProcessing(false);
    }
  }, []);

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: {
      'image/jpeg': ['.jpeg'],
      'image/png': ['.png'],
    },
    maxFiles: MAX_NUMBER_OF_FILES,
    maxSize: MAX_UPLOAD_FILE_SIZE,
    onDrop,
  });

  const onChooseAnother = () => inputRef.current?.click();
  const onImageUpload = async (file: File) => {
    setProcessing(true);

    try {
      const filePath = await uploadToBucket(filename, file, type);
      const bucket = import.meta.env.VITE_SPACES_BUCKET;
      const region = import.meta.env.VITE_SPACES_REGION;
      // console.log('filePath', filePath);
      onClose(`https://${bucket}.${region}.digitaloceanspaces.com/${filePath}`);
    } catch (e) {
      //  console.error('Error uploading image', e);
      captureException(e);
    } finally {
      setProcessing(false);
    }
  };
  const onUpload = () => {
    if (preparedFile) {
      onImageUpload(preparedFile);
    }
  };
  const onCancel = () => onClose();

  return (
    <Dialog
      aria-describedby="modal-upload"
      aria-labelledby="modal-upload-title"
      open
    >
      <DialogTitle>Upload</DialogTitle>
      <DialogContent>
        <div {...getRootProps({ className: 'dropzone' })}>
          <input ref={inputRef} {...getInputProps()} type="file" />
          {uploadedImage ? (
            <Preview
              preview={uploadedImage}
              type={type}
              onChooseAnother={onChooseAnother}
            />
          ) : (
            <DragAndDrop
              fileRejections={fileRejections}
              onBrowse={onChooseAnother}
            />
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={!preparedFile}
          loading={processing}
          variant="contained"
          onClick={onUpload}
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadImage;
