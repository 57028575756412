import { useNavigate } from 'react-router-dom';

import { useCreateMessageThread } from '@/hooks';
import { WORKFLOW_CATEGORY } from '@/types';
import { Icon, IconButton, TextFieldWithButton, Typography } from '@/ui';

import Header from './Header';
import WorkflowsSessionHistory from './WorkflowsSessionHistory';

import type { MessageThread } from '@/types';

const CoachAI = () => {
  const createMessageThread = useCreateMessageThread();
  const navigate = useNavigate();

  const goHome = () => {
    navigate('/m');
  };

  const onSubmit = (content: string) => {
    const onSuccess = (data: MessageThread) => {
      navigate(`/m/workflow/${data.id}`);
    };

    createMessageThread.mutate(
      {
        content,
        workflow: WORKFLOW_CATEGORY.GROW,
      },
      { onSuccess },
    );
  };

  return (
    <div className="size-full">
      <Header
        backButton={
          <IconButton className="ml-3" color="primary" onClick={goHome}>
            <Icon>close</Icon>
          </IconButton>
        }
      />

      <div className="flex size-full justify-center">
        <div className="mt-60 max-w-110 md:max-w-156">
          <Typography className="mb-5 text-black" variant="h3">
            Mento AI Coach
          </Typography>
          <Typography className="mb-10  text-cement-500" variant="h6">
            Welcome, I’m the new Mento AI coach trained by the Mento Coaching
            team. My goal is to help you work through a career or work
            challenge. <b>What’s on your mind today?</b>
          </Typography>

          <TextFieldWithButton
            buttonIcon="arrow_upward"
            disabled={createMessageThread.isLoading}
            placeholder="Talk with Mento"
            onSubmit={onSubmit}
          />

          <WorkflowsSessionHistory />
        </div>
      </div>
    </div>
  );
};

export default CoachAI;
