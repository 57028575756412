import { useLocation } from 'react-router-dom';

import { Typography } from '@/ui';

const MarketingInfo = () => {
  const location = useLocation();
  const isCoach = !!location.pathname.match('/coach');

  if (isCoach) {
    return null;
  }

  return (
    <div className="flex size-full flex-col justify-center p-4 md:w-1/2 md:pl-20">
      <img
        alt="logo"
        className="ml-5 md:absolute md:left-20 md:top-20 md:ml-0"
        height="53"
        src="https://mento-space.nyc3.cdn.digitaloceanspaces.com/logo.svg"
        width="140"
      />

      <div className="p-4 text-cement-500 md:mb-12 md:p-0">
        <Typography
          className="mb-6 text-cement-500"
          // eslint-disable-next-line react/forbid-component-props -- Acceptable way of doing responsive typography.
          sx={{ typography: { md: 'h3', sm: 'h5', xs: 'h5' } }}
        >
          1-on-1 coaching from an incredible team who've been there, done that.
        </Typography>
        <Typography
          className="text-cement-500"
          // eslint-disable-next-line react/forbid-component-props -- Acceptable way of doing responsive typography.
          sx={{ typography: { md: 'body1', sm: 'body2', xs: 'body2' } }}
        >
          Unlock your growth with personalized coaching from industry
          professionals, specialized tools, and community support.
        </Typography>
      </div>
    </div>
  );
};

export default MarketingInfo;
