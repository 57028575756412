import { useQueryClient } from '@tanstack/react-query';
import { useController, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
  GROWTH_STEP_DATA,
  QUERY_KEY_GROWTH_PLANS,
  QUERY_KEY_MILESTONES,
  ZERO,
} from '@/constants';
import {
  useCreateMilestone,
  useCurrentGrowthBaseUrl,
  useGrowthPlan,
  useUpdateMilestone,
} from '@/hooks';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Icon,
  TextField,
  Typography,
} from '@/ui';

import type { Milestone } from '@/types';
import type { FC } from 'react';

interface MilestoneDialogProps {
  isOpen: boolean;
  milestone?: Partial<Milestone>;
  setOpen: (value: boolean) => void;
}

const MilestoneDialog: FC<MilestoneDialogProps> = ({
  milestone = {} as Milestone,
  isOpen,
  setOpen,
}) => {
  const { limit } = GROWTH_STEP_DATA[2]; // Milestone Dialog Data
  const {
    control,
    formState: { isValid },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      description: '',
      title: '',
    },
    values: milestone,
  });
  // TODO: For reasons I cannot figure out, the first time this modal is
  // opened in an edit situation, it shows blank values. If you close and reopen
  // it shows the real values.
  const { field: title, fieldState: titleState } = useController({
    control,
    name: 'title',
    rules: { maxLength: limit, required: true },
  });
  const { field: description } = useController({
    control,
    name: 'description',
  });
  const { plan } = useGrowthPlan();
  const createMilestone = useCreateMilestone();
  const updateMilestone = useUpdateMilestone();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const growthPath = useCurrentGrowthBaseUrl();
  const isLoading = createMilestone.isLoading || updateMilestone.isLoading;

  const getLengthHelperText = (max: number, current?: number) =>
    `${max - (current || ZERO)} characters left`;

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const onSubmit = handleSubmit((data) => {
    const onSuccess = () => {
      queryClient.invalidateQueries([QUERY_KEY_GROWTH_PLANS]);
      queryClient.invalidateQueries([QUERY_KEY_MILESTONES, milestone.id]);
      reset();
      setOpen(false);
    };

    if (milestone.id) {
      updateMilestone.mutate({ id: milestone.id, ...data }, { onSuccess });
    } else {
      createMilestone.mutate(
        { ...data, growthPlanId: plan.id },
        {
          onSuccess: (newMilestone) => {
            onSuccess();
            navigate(`${growthPath}/milestone/${newMilestone.id}`);
          },
        },
      );
    }
  });

  const canSave =
    isValid && !createMilestone.isLoading && !updateMilestone.isLoading;

  return (
    <Dialog maxWidth="md" open={isOpen} fullWidth onClose={handleClose}>
      <DialogContent>
        <form id="add-milestone-form" onSubmit={onSubmit}>
          <Grid spacing={4} container>
            <Grid md={5} xs={12} item>
              <div className="flex size-12 items-center justify-center rounded-full bg-sage-600 text-white">
                <Icon>landscape</Icon>
              </div>
              <Typography className="my-4" variant="h4">
                {milestone.title ? 'Edit Milestone' : 'Create a Milestone'}
              </Typography>
              <Typography className="mb-12" variant="body1">
                Milestones are 1-3 month goals that get you closer to your
                one-year vision. You can have up to three milestones at a time
                to focus your growth plan.
              </Typography>
            </Grid>
            <Grid className="mt-4 flex flex-col gap-4" md={7} xs={12} item>
              <TextField
                className="mb-4 w-full"
                error={titleState.invalid}
                helperText={getLengthHelperText(limit, title.value?.length)}
                inputProps={{ maxLength: limit }}
                label="Title"
                {...title}
              />

              <TextField
                className="w-full"
                label="Description"
                rows={4}
                multiline
                {...description}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <div className="flex items-end justify-end gap-4">
          <Button disabled={isLoading} variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={!canSave}
            form="add-milestone-form"
            loading={isLoading}
            type="submit"
            variant="contained"
          >
            Save
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default MilestoneDialog;
