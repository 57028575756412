import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { getUser } from '@/api';
import { useCurrentUser } from '@/hooks';

import type { User } from '@/types';

import Banner from '@/components/Profile/Banner';
import Bio from '@/components/Profile/Bio';
import Details from '@/components/Profile/Coach/Details';
import EditCoachAdmin from '@/components/Profile/Coach/Edit/Admin';
import EditCoach from '@/components/Profile/Coach/Edit/Coach';
import Experience from '@/components/Profile/Coach/Experience';
import Styles from '@/components/Profile/Coach/Styles';

// TODO Combine with src/pages/profile
const Profile = () => {
  const { id = '' } = useParams();
  const { isUserLoading } = useCurrentUser();

  const [isEditing, setIsEditing] = useState(false);
  const [isEditingAdminDetails, setIsEditingAdminDetails] = useState(false);

  const {
    data: coach,
    isLoading,
    refetch,
  } = useQuery<User>(['user', id], () => getUser(id), {
    enabled: !!id,
  });

  const onEditingCoach = () => setIsEditing(true);
  const onEditingCoachClose = () => setIsEditing(false);
  const onEditingAdminDetails = () => setIsEditingAdminDetails(true);
  const onEditingAdminDetailsClose = () => setIsEditingAdminDetails(false);

  if (isLoading || isUserLoading || !coach) {
    return null;
  }

  return (
    <div className="flex-1 flex-col bg-sunny-100 md:flex">
      <div className="w-full bg-sage-500 pl-2 pt-4 lg:pl-8 lg:pt-0">
        <Banner user={coach} onEdit={onEditingCoach} />
      </div>
      <div className="block px-6 py-4 lg:container md:px-8 lg:mt-8 lg:flex lg:flex-1 lg:flex-row-reverse lg:px-12">
        <div className="space-y-8 px-0 lg:flex-1 lg:px-10">
          <Bio user={coach} />
          <Experience
            coach={coach}
            industries={coach.coachProfile?.industries}
          />
          <Styles styles={coach.coachProfile?.styles ?? []} />
        </div>
        <div className="-mx-8 -mb-4 mt-12 bg-sage-200 px-8 py-4 lg:mx-0 lg:mt-6 lg:size-64 lg:bg-transparent lg:px-0 lg:pb-6">
          <Details coach={coach} onEditAdmin={onEditingAdminDetails} />
        </div>
      </div>

      {!!isEditingAdminDetails && (
        <EditCoachAdmin
          coach={coach}
          refetchCoach={refetch}
          onClose={onEditingAdminDetailsClose}
        />
      )}
      {!!isEditing && (
        <EditCoach
          coach={coach}
          refetchCoach={refetch}
          onClose={onEditingCoachClose}
        />
      )}
    </div>
  );
};

export default Profile;
