import clsx from 'clsx';

import { SESSION_FEEDBACK_TYPES } from '@/constants';
import { SessionFeedbackType } from '@/types';
import { Typography } from '@/ui';

import type { Session, SessionFeedbackParams } from '@/types';
import type { FC } from 'react';

const FeedbackIcon: FC<{
  feedbackType: SessionFeedbackType;
  onClick: () => void;
  selected?: SessionFeedbackType | null;
  session: Session;
}> = ({ feedbackType, selected = null, onClick, session }) => {
  const {
    background = '',
    border = '',
    img = '',
  } = SESSION_FEEDBACK_TYPES[feedbackType] ?? ({} as SessionFeedbackParams);
  const selectedBorder =
    selected === feedbackType ? border : 'border-transparent';
  const isChemistry = session.isChemistryCall;

  const generateIconMessage = (isChem: boolean, type: SessionFeedbackType) => {
    switch (type) {
      case SessionFeedbackType.POSITIVE:
        return isChem
          ? `${session.coach.fullName} is a great match for me!`
          : `I had a great session with ${session.coach.firstName}!`;
      case SessionFeedbackType.MIXED:
        return isChem
          ? `${session.coach.fullName} was a good match but I'm still deciding.`
          : `My session with ${session.coach.firstName} was okay.`;
      case SessionFeedbackType.NEGATIVE:
        return isChem
          ? `${session.coach.fullName} and I were not a good match.`
          : `I did not have a great session with ${session.coach.firstName}.`;
      default:
        return '';
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions -- TODO: Improve this accessibility.
    <div
      className={clsx(
        'flex cursor-pointer flex-col items-center gap-4 rounded-xl border-2 p-4 sm:flex-row',
        background,
        selectedBorder,
      )}
      onClick={onClick}
    >
      <img alt="feedback icon" className="size-12" src={img} />
      <Typography className="text-cement-500 sm:text-left">
        {generateIconMessage(isChemistry, feedbackType)}
      </Typography>
    </div>
  );
};

export default FeedbackIcon;
