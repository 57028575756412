import clsx from 'clsx';
import { useState } from 'react';

import { Icon, Typography } from '@/ui';

import type { FC } from 'react';

interface RowProps {
  body: string;
  header: string;
  src: string;
}

const Row: FC<RowProps> = ({ body, header, src }) => {
  const [closed, setClosed] = useState(true);
  const toggle = () => setClosed(!closed);
  const icon = closed ? 'expand_more' : 'expand_less';

  return (
    <div className="border-b border-sage-500 p-6 last:border-none md:pointer-events-none md:mx-2 lg:mx-0">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions -- TODO: Improve this accessibility */}
      <div className="flex items-start justify-between gap-8" onClick={toggle}>
        <div className="flex min-w-70 cursor-pointer gap-4 lg:w-1/2">
          <img alt={header} src={src} width="50" />
          <Typography variant="h4">{header}</Typography>
        </div>
        <Typography
          className="mt-2 hidden text-sage-300 md:mt-0 md:flex md:w-1/2"
          variant="body1"
        >
          {body}
        </Typography>
        <div
          className={clsx(
            'flex size-8 shrink-0 cursor-pointer justify-center rounded-full bg-white py-1 text-sage-500 md:hidden',
            { 'bg-sage-300 text-sage-500': !closed },
          )}
        >
          <Icon>{icon}</Icon>
        </div>
      </div>

      <Typography
        className={clsx('ml-0.5 mt-2 block pl-16 text-sage-300 md:hidden', {
          hidden: closed,
        })}
        variant="body1"
      >
        {body}
      </Typography>
    </div>
  );
};

export default Row;
