import clsx from 'clsx';

import { UploadType } from '@/types';
import { Button } from '@/ui';

import type { FC } from 'react';

interface PreviewProps {
  onChooseAnother: () => void;
  preview: string;
  type: UploadType;
}

const Preview: FC<PreviewProps> = ({ preview, type, onChooseAnother }) => (
  <div className="flex items-center justify-center gap-4">
    <img
      alt="upload-preview"
      className={clsx('mx-auto my-2 size-36 object-cover', {
        'rounded-full': type !== UploadType.EVENT,
      })}
      src={preview}
    />
    <Button color="info" variant="outlined" onClick={onChooseAnother}>
      Choose a different file
    </Button>
  </div>
);

export default Preview;
