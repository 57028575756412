import { useContext } from 'react';

import { AppContext } from '@/context';
import { useCurrentUser } from '@/hooks';
import { Button, Icon, Typography } from '@/ui';

import type { User } from '@/types';
import type { FC } from 'react';

type EmptyProps = {
  client: User;
};

const Empty: FC<EmptyProps> = ({ client }) => {
  const { setChatId } = useContext(AppContext);
  const { cometChatId, fullName, coaches = [] } = client;
  const { user } = useCurrentUser();

  const coachLinks = coaches
    .filter(
      ({ superAdmin, coachProfile }) =>
        !superAdmin && coachProfile?.calendarUrl,
    )
    .map(({ id: mappedId, coachProfile }) => {
      const username = coachProfile?.calendarUrl;
      const calLink = `${import.meta.env.VITE_CAL_URL}/${username}`;
      const envLink = calLink.replace('https://', '');
      return { calLink, envLink, id: mappedId, username };
    });

  const onMessage = () => setChatId(cometChatId);
  const onCopyCalLink = (link: string) => () =>
    navigator.clipboard.writeText(link);

  return (
    <>
      <div className="grid size-12 place-items-center rounded-full bg-sage-200">
        <Icon className="!text-2xl text-sage-600">calendar_add_on</Icon>
      </div>
      <div className="pt-4">
        <Typography className="mb-2" variant="h5">
          No upcoming sessions scheduled
        </Typography>
        <Typography className="mb-5 pr-20" variant="body2">
          Consider reaching out to encourage them to schedule their next
          session. You can copy and share your scheduling link with them below
          for your convenience.
        </Typography>
      </div>
      <div className="flex flex-wrap gap-2">
        {!user.superAdmin && (
          <Button variant="outlined" onClick={onMessage}>
            Message {fullName}
          </Button>
        )}
        {coachLinks.map(({ id: linkId, calLink, envLink }) => (
          <Button
            key={`${linkId}-coach-links`}
            color="gray"
            endIcon={<Icon className="m-1">content_copy</Icon>}
            size="large"
            variant="contained"
            onClick={onCopyCalLink(calLink)}
          >
            <Typography variant="caption">{envLink}</Typography>
          </Button>
        ))}
      </div>
    </>
  );
};

export default Empty;
