import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { joinTeam } from '@/api';
import { HTTP_NOT_FOUND, HTTP_UNAUTHORIZED } from '@/constants';
import { AppContext } from '@/context';
import { AppMessageSeverities } from '@/types';
import { Button, Icon, Skeleton, Typography } from '@/ui';

import ClientCard from '../Members/List/ClientCard';

import type { User } from '@/types';
import type { TeamMember } from '@/types/teams';

import AddMembers from '@/components/Teams/AddMembers';
import EditTeam from '@/components/Teams/EditTeam';
import GoalsCard from '@/components/Teams/GoalsCard';
import GoalsModal from '@/components/Teams/GoalsModal';
import { useTeam } from '@/hooks/useTeam';

// WARNING: any change needs to be mirrored in its sendgrid template, its ID its in the backend env.
// this comes from the backend / email
const JOIN_BY_LINK_PARAM = 'status';

const Team = () => {
  const navigate = useNavigate();
  const { selectedTeamId, setAppMessage } = useContext(AppContext);
  const [searchParams] = useSearchParams();
  const [openAddMembers, setOpenAddMembers] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [openGoals, setOpenGoals] = useState(false);
  const action = searchParams.get(JOIN_BY_LINK_PARAM);
  const teamId = selectedTeamId;
  const { team, isTeamLoading, teamError } = useTeam(teamId, action);

  useEffect(() => {
    if (!action || !teamId) return;

    const doJoin = async () => {
      try {
        await joinTeam(teamId);
        navigate(`/teams/${teamId}`, { replace: true });
      } catch (error) {
        setAppMessage({
          message: 'Error joining this team',
          open: true,
          severity: AppMessageSeverities.Error,
        });
        console.error('Error joining team!!', error);
      }
    };
    doJoin();
  }, [action]);

  const openTeamGoals = () => setOpenGoals(true);

  const closeTeamGoals = () => setOpenGoals(false);

  if (teamError) {
    if (
      teamError.response?.status === HTTP_NOT_FOUND ||
      teamError.response?.status === HTTP_UNAUTHORIZED
    ) {
      navigate('/teams');
      return null;
    }

    return (
      <div className="flex h-screen">
        <div className="m-auto">
          <Typography>There was an error loading this team.</Typography>
        </div>
      </div>
    );
  }

  if (isTeamLoading || action) {
    return (
      <div className="px-32 pt-10">
        <div className="flex justify-between">
          <div>
            <Skeleton height={40} variant="text" width={160} />
          </div>

          <div className="flex gap-2">
            <Skeleton height={40} variant="rectangular" width={40} />
            <Skeleton height={40} variant="rectangular" width={20} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="px-4 pt-10 md:px-32">
      <div className="flex justify-between">
        <div>
          <Typography className="pb-2 text-cement-600" variant="h5">
            {team.name}
          </Typography>
          <Typography variant="caption">
            {team.teamMembers.length} members
          </Typography>
        </div>
        <div>
          <Button
            startIcon={<Icon>add_circle</Icon>}
            variant="outlined"
            onClick={() => setOpenAddMembers(true)}
          >
            Add People
          </Button>
          <Button
            className="ml-2 min-w-min p-2"
            variant="outlined"
            onClick={() => setOpenSettings(true)}
          >
            <Icon>settings</Icon>
          </Button>
        </div>
      </div>
      <div className="my-8 flex h-full gap-5">
        {}
        <div className="grid w-full grid-cols-1 gap-4 lg:pr-0 xl:grid-cols-4 [@media(min-width:1000px)]:grid-cols-2 [@media(min-width:1270px)]:grid-cols-3">
          {team.teamMembers.map((member: TeamMember) => {
            const { status = '', userId } = member;
            const fullTeamMember = { ...member.user, id: userId, status };
            return (
              <ClientCard
                key={`member-list-${member.id}`}
                navigatePath={`/teams/${teamId}/member`}
                user={fullTeamMember as User}
                hideFooter
                hidePlan
                hideStatus
              />
            );
          })}
        </div>
        <GoalsCard openTeamGoals={openTeamGoals} team={team} />
      </div>

      {openAddMembers && (
        <AddMembers team={team} onClose={() => setOpenAddMembers(false)} />
      )}
      {openSettings && (
        <EditTeam team={team} onClose={() => setOpenSettings(false)} />
      )}

      {openGoals && <GoalsModal team={team} onClose={closeTeamGoals} />}
    </div>
  );
};

export default Team;
