import { ONE, ZERO } from '@/constants';

import type { Client } from './client';

export interface Recipient {
  email?: string;
  name?: string;
  relationship?: string;
}

export interface SurveyStep {
  body: string;
  createdAt: Date;
  icon: string;
  id: string;
  surveyTemplate: SurveyTemplate;
  surveyTemplateId: string;
  title: string;
  updatedAt: Date;
}

export interface SurveyTemplate {
  allowedUsers: string[];
  color: string;
  createdAt: Date;
  description: string;
  emailTemplate: string;
  exampleSurvey?: Survey;
  exampleSurveyId: string | null;
  expiration: number | null;
  icon: string;
  id: string;
  maximumRecipients: number;
  minimumCompletionPercentage: number;
  minimumRecipients: number;
  name: string;
  steps?: SurveyStep[];
  surveyQuestions: SurveyQuestion[];
  surveys?: Survey[];
  typeformId: string;
  updatedAt: Date;
}

export interface Survey {
  client?: Client;
  clientId: string | null;
  completedAt: string;
  createdAt: Date;
  id: string;
  responses?: SurveyResponse[];
  status: SurveyStatus;
  surveyTemplate?: SurveyTemplate;
  surveyTemplateId: string;
  title: string;
  updatedAt: Date;
}

export interface SurveyResponse {
  createdAt: Date;
  email: string;
  id: string;
  name: string;
  relationship?: string;
  respondedAt: Date | null;
  results?: SurveyResult;
  status: SurveyResponseStatus;
  surveyId: string;
  updatedAt: Date;
}

export interface SurveyQuestion {
  name?: string;
  options?: string[];
  ref?: string;
  subtext?: string;
}

export interface SurveyResult {
  [index: string]: {
    choices?: string[];
    text?: string;
  };
}

export interface SummarySlide {
  name?: string;
  options?: string[];
  ref?: string;
  subtext?: string;
  text?: string;
}

export interface TypeformAnswer {
  choices?: {
    labels: string[];
  };
  field: {
    ref: string;
  };
  text?: string;
  type: string;
}

export interface StepData {
  button?: string;
  buttonIcon?: string;
  callback?: () => void | Promise<void>;
  layout?: string;
  name: string;
  subtitle?: string;
  title: string;
  titleIcon?: string;
}

export interface SurveyResultsResponse {
  results: SurveyResult[];
  survey: Survey;
}

export enum SurveyChoiceText {
  EVERYONE = 'Everyone',
  MOST = 'Most',
  NO_ONE = 'No one',
  SOME = 'Some',
}

export enum SurveyResponseStatus {
  BOUNCED = 'BOUNCED',
  COMPLETED = 'COMPLETED',
  RECEIVED = 'RECEIVED',
  REJECTED = 'REJECTED',
  STARTED = 'STARTED',
}

export enum SurveyStatus {
  COACH_REVIEWING = 'COACH_REVIEWING',
  COMPLETED = 'COMPLETED',
  DRAFT = 'DRAFT',
  IN_PROGRESS = 'IN_PROGRESS',
  SHARED = 'SHARED',
}

export enum SurveyResponseRelationship {
  CO_WORKER = 'CO_WORKER',
  MY_DIRECT_REPORT = 'MY_DIRECT_REPORT',
  MY_MANAGER = 'MY_MANAGER',
  OTHER = 'OTHER',
  PREVIOUS_CO_WORKER = 'PREVIOUS_CO_WORKER',
  TEAM_MEMBER = 'TEAM_MEMBER',
}

export enum ResponsesTabs {
  Summary = ZERO,
  IndividualResponses = ONE,
}
