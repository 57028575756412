import { Typography } from '@/ui';

import type { FC } from 'react';
import type { FileRejection } from 'react-dropzone';

interface DragAndDropProps {
  fileRejections: readonly FileRejection[];
  onBrowse: () => void;
}

const DragAndDrop: FC<DragAndDropProps> = ({ fileRejections, onBrowse }) => {
  let rejectionError = '';

  if (fileRejections.length) {
    switch (fileRejections[0]?.errors?.[0]?.code || '') {
      case 'file-too-large':
        rejectionError = 'File is larger than 2mb.';
        break;
      case 'too-many-files':
        rejectionError = 'More than 1 file was selected.';
        break;
      case 'file-invalid-type':
        rejectionError = 'File type is not supported';
        break;
      default:
        rejectionError = '';
    }
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions -- TODO: Improve accessibility (Use Card component, maybe?)
    <div
      className="cursor-pointer border border-dashed border-sunny-500 bg-sunny-100 p-2"
      onClick={onBrowse}
    >
      <Typography className="text-center" variant="subtitle1">
        Drag and drop files here, or click to browse
      </Typography>
      <Typography className="mt-4 text-center" variant="body1">
        Accepted file types include: .png, .jpg, and .jpeg.
      </Typography>
      <Typography className="text-center" variant="body2">
        Limit 1 file to upload less than 2mb.
      </Typography>
      {!!rejectionError && (
        <Typography className="mt-4 text-poppy-600" variant="subtitle2">
          {rejectionError}
        </Typography>
      )}
    </div>
  );
};

export default DragAndDrop;
